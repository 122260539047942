import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import compose from 'recompose/compose'
import numeral from 'numeral'
import moment from 'moment'
import { connect } from 'react-redux'
import AppRouter from '../routes/AppRouter'
import { loadLookups, loadCompanies, loadUsers } from 'Actions/cache'
import { loadCountries, loadLanguages } from 'Actions/lookup'

class IntlApp extends Component {
  componentDidMount() {
    this.setLanguage(this.props.language)
  }

  componentDidUpdate(prevProps) {
    const { language, isAuthenticated } = this.props
    if (
      language !== prevProps.language ||
      isAuthenticated !== prevProps.isAuthenticated
    ) {
      this.setLanguage(language)
    }
  }

  setLanguage(language) {
    const {
      isAuthenticated,
      loadCompanies,
      loadCountries,
      loadLanguages,
      loadLookups,
      loadUsers,
    } = this.props
    numeral.locale(language)
    moment.locale(language)
    loadCountries()
    loadLanguages()
    if (isAuthenticated) {
      loadLookups()
      loadCompanies()
      loadUsers()
    }
  }

  render() {
    return <AppRouter />
  }
}

export default compose(
  injectIntl,
  connect(
    (state) => ({
      isAuthenticated: state.auth.info.authenticated,
      language: state.app.language,
    }),
    { loadCompanies, loadCountries, loadLanguages, loadLookups, loadUsers }
  )
)(IntlApp)
