import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { FileDrop } from 'react-file-drop'
import clsx from 'clsx'
import uniqueId from 'lodash.uniqueid'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import formatBytes from 'Utils/formatBytes'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import CircularProgress from '@material-ui/core/CircularProgress'
import ClearIcon from '@material-ui/icons/Clear'
import Tooltip from 'Components/common/Tooltip'
import { showMessage } from 'Actions/shell'
import { messageLevel } from 'Utils/constants'
import { t } from 'Root/app/IntlProvider'

const styles = (theme) => {
  return {
    background: {
      backgroundColor: theme.palette.divider,
    },
    busy: {
      paddingLeft: 10,
      display: 'flex',
      flexDirection: 'column',
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      paddingBottom: 10,
      paddingTop: 10,
    },
    docIcon: {
      marginRight: 10,
      fill:
        theme.palette.type === 'light'
          ? theme.palette.grey[600]
          : theme.palette.grey[400],
    },
    hidden: {
      display: 'none',
    },
    input: {
      display: 'none',
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    name: {
      flex: '1 1 auto',
    },
    newDocIcon: {
      marginLeft: 5,
    },
    rightIcon: {},
    spinner: {
      marginRight: 10,
    },
  }
}

class Upload extends React.Component {
  state = {
    name: '',
    type: '',
    size: undefined,
    uploadId: uniqueId('upload_'),
  }
  static getDerivedStateFromProps(props) {
    if (props.listValue) {
      return {
        name: props.listValue.name,
        size: props.listValue.size,
      }
    }
    return null
  }

  handleInputChange = (e) => {
    for (const file of e.target.files) {
      this.uploadFile(file)
      if (!this.props.multi) {
        e.currentTarget.value = null
        return
      }
    }
    e.currentTarget.value = null
  }

  handleClearClick = () => {
    const values = { name: '', size: undefined, type: '' }
    if (this.props.onClear) {
      this.props.onClear()
    }
    this.setState(() => ({ ...values }))
    if (this.props.input && this.props.input.onChange) {
      this.props.input.onChange(values)
    }
    if (this.props.onFileLoad) {
      this.props.onFileLoad({ target: { value: '' } }, {})
    }
  }

  uploadFile = (file) => {
    if (!(file && file.name)) {
      // no file
      return
    }
    if (file.size > 8 * 1024 * 1000) {
      this.props.showMessage(
        t('common.form.upload.error.size'),
        messageLevel.error
      )
      return
    }
    const types = (this.props.extensions || '').toLowerCase().split('|')
    if (types.length > 1) {
      const ext = (file.name || '').toLowerCase().split('.').pop()
      if (!types.includes(ext)) {
        // message
        this.props.showMessage(
          t('common.form.upload.error.file', { types: types.join(', ') }),
          messageLevel.error
        )
        return
      }
    }

    // check file extension
    const reader = new FileReader()
    reader.onload = (e) => {
      const data = e.target.result
      if (!this.props.multi) {
        const values = { name: file.name, size: file.size, type: file.type }
        this.setState({ ...values })
        if (this.props.input && this.props.input.onChange) {
          this.props.input.onChange({ ...values, data })
        }
      }
      if (this.props.onFileLoad) {
        this.props.onFileLoad(e, file, data)
      }
    }
    reader.readAsDataURL(file)
  }

  handleDrop = (files) => {
    for (const file of files) {
      this.uploadFile(file)
      if (!this.props.multi) {
        return
      }
    }
  }

  render() {
    const {
      busy,
      busyText,
      button,
      buttonIconColor = 'primary',
      buttonClassName,
      classes,
      children,
      docIcon,
      multi = false,
      showName = true,
      text,
      title,
      showUploading = false,
      uploading = false,
      uploadIcon,
    } = this.props
    const Component = button || Button
    const { name, size, uploadId } = this.state
    const newUploadIcon =
      uploadIcon ||
      React.cloneElement(<CloudUploadIcon />, {
        className: clsx((children || text) && classes.leftIcon),
      })
    const newDocIcon = docIcon
      ? React.cloneElement(docIcon, {
          className: clsx(classes.leftIcon, classes.docIcon),
        })
      : null
    return (
      <>
        {busy && (
          <div
            className={clsx(
              !button && classes.container,
              !button && classes.background,
              classes.busy
            )}
          >
            <CircularProgress
              size={24}
              className={classes.spinner}
              color={buttonIconColor}
            />
            <Typography variant="body1">{busyText}</Typography>
          </div>
        )}
        <FileDrop onDrop={this.handleDrop}>
          <div
            className={clsx(
              !button && classes.container,
              name && !button && classes.background,
              busy && classes.hidden
            )}
          >
            {name && !!newDocIcon && (
              <div className={classes.newDocIcon}>{newDocIcon}</div>
            )}
            {(!name || showUploading) && (
              <div>
                <input
                  key={uploadId}
                  className={classes.input}
                  id={uploadId}
                  type="file"
                  multiple={multi}
                  onChange={this.handleInputChange}
                />
                <label htmlFor={uploadId}>
                  <Tooltip title={title}>
                    <Component
                      color={buttonIconColor}
                      disabled={uploading}
                      component="span"
                      className={buttonClassName}
                    >
                      {newUploadIcon}
                      {children || text}
                    </Component>
                  </Tooltip>
                </label>
              </div>
            )}
            {showName && (
              <div className={classes.name}>
                <Typography variant="body1">{name}</Typography>
                <Typography variant="body1">
                  <em>{formatBytes(size)}</em>
                </Typography>
              </div>
            )}
            {!!name && showName && (
              <div>
                <IconButton
                  className={clsx(classes.rightIcon)}
                  onClick={this.handleClearClick}
                >
                  <ClearIcon />
                </IconButton>
              </div>
            )}
          </div>
        </FileDrop>
      </>
    )
  }
}

export default compose(
  withStyles(styles),
  connect(null, {
    showMessage,
  })
)(Upload)
