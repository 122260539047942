import { call, put, select } from 'redux-saga/effects'
import { show } from 'redux-modal'
import moment from 'moment'
import { http } from 'Utils/http'
import { confirmSaga, globalError, messageSaga } from './shell'
import {
  addSources,
  getCandidatesSucceed,
  getPlanning,
  getPlanningSucceed,
  getTimelinesSucceed,
  setInterval,
  setLoading,
} from 'Actions/project'
import { t } from 'Root/app/IntlProvider'
import { gotoUrl } from 'Actions/app'
import { showMessage } from 'Actions/shell'
import { messageLevel } from 'Utils/constants'

export function* addTimelineSaga(action) {
  const {
    payload: { jobId, projectId, reject, resolve, values },
  } = action
  const { created, error, data } = yield http.post(
    `/projects/${projectId}/jobs/${jobId}/timelines`,
    values
  )
  if (created) {
    yield put(getPlanning(projectId))
    yield call(resolve)
  } else if (error) {
    yield call(globalError, data, reject)
  }
}

export function* checkTimelineSaga(action) {
  const {
    payload: { jobId, projectId, timelineId, reject, resolve, values },
  } = action
  const { ok, error, data } = yield http.post(
    `/projects/${projectId}/jobs/${jobId}/timelines/check`,
    {
      ...values,
      timelineId,
    }
  )
  if (ok) {
    if (data.length > 0) {
      const {
        candidateName: candidate,
        projectName: project,
        jobName: job,
        startAt,
        endAt,
      } = data[0]
      yield call(messageSaga, {
        title: t('project.projectPlanning.checkTimeline.title'),
        content: t('project.projectPlanning.checkTimeline.content', {
          project,
          candidate,
          job,
          startAt: moment(startAt).format('D-M-YYYY'),
          endAt: moment(endAt).format('D-M-YYYY'),
        }),
      })
      yield call(reject)
    } else {
      yield call(resolve)
    }
  } else if (error) {
    yield call(globalError, data, reject)
  }
}

export function* deleteTimelineSaga(action) {
  const { projectId, id } = action
  const { noContent, error, data } = yield http.delete(
    `/projects/${projectId}/timelines/${id}`
  )
  if (noContent) {
    yield put(getPlanning(projectId))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* editTimelineSaga(action) {
  const {
    payload: { callback, projectId, id, reject, resolve, values },
  } = action
  const { ok, error, data } = yield http.put(
    `/projects/${projectId}/timelines/${id}`,
    values
  )
  if (ok) {
    if (callback) {
      yield put(callback)
    } else {
      yield put(getPlanning(projectId))
    }
    yield call(resolve)
  } else if (error) {
    yield call(globalError, data, reject)
  }
}

export function* getCandidatesSaga(action) {
  const { edit, id, jobId } = action
  const { ok, error, data } = yield http.get(
    `/projects/${id}/jobs/${jobId}/candidates`
  )
  if (ok) {
    yield put(getCandidatesSucceed(data, edit))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getPlanningSaga(action) {
  const { id } = action
  const { ok, error, data } = yield http.get(`/projects/${id}/planning`)
  if (ok) {
    yield put(getPlanningSucceed(data))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getTimelineSaga(action) {
  const { id, callback } = action
  const { ok, data, notFound } = yield http.get(`/projects/timelines/${id}`)
  if (ok) {
    yield put(
      show('timeline', {
        callback,
        jobId: data.jobId,
        projectId: data.projectId,
        editMode: true,
        timeline: {
          candidateId: data.candidateId,
          color: data.color,
          costprice: data.costprice,
          currency: data.currency,
          endAt: data.endAt,
          hoursPerDay: data.hoursPerDay,
          id: data.id,
          info: data.info,
          rate: data.rate,
          startAt: data.startAt,
        },
      })
    )
  } else if (notFound) {
    yield put(
      showMessage(t('project.message.notFoundTimeline'), messageLevel.error)
    )
  } else {
    yield call(globalError, data)
  }
}

export function* getTimelinesSaga(action) {
  const { deployed, end, start } = action
  const state = yield select()
  const startAt = moment(start || state.project.timelines.interval.start)
  const endAt = moment(end || state.project.timelines.interval.end)
  yield put(setLoading(true))
  const { ok, error, data } = yield http.post(`/projects/timelines`, {
    deployed,
    endAt,
    startAt,
  })
  if (ok) {
    yield put(getTimelinesSucceed(data))
  } else if (error) {
    yield call(globalError, data)
  }
  yield put(setLoading(false))
}

export function* gotoProjectSaga(action) {
  const { id, newTab } = action
  const state = yield select()
  const { start, end } = state.project.timelines.interval
  yield put(setInterval(start, end))
  yield put(
    gotoUrl({
      newTab,
      prevLocationEntity: window.location.pathname,
      url: `/projects/${id}/planning`,
    })
  )
}

export function* jobMoveDownSaga(action) {
  const { projectId, jobId } = action
  const { error, data } = yield http.post(
    `/projects/${projectId}/jobs/${jobId}/movedown`
  )
  if (error) {
    yield call(globalError, data)
  }
}

export function* jobMoveUpSaga(action) {
  const { projectId, jobId } = action
  const { error, data } = yield http.post(
    `/projects/${projectId}/jobs/${jobId}/moveup`
  )
  if (error) {
    yield call(globalError, data)
  }
}

export function* loadSourcesSaga() {
  const { ok, data } = yield http.get('/projects/sources')
  if (ok) {
    yield put(addSources(data))
  } else {
    yield call(globalError, data)
  }
}

export function* removeJobSaga(action) {
  const { id, jobId } = action
  const ok = yield call(confirmSaga, {
    title: t('project.projectPlanning.removeJob.title'),
    content: t('project.projectPlanning.removeJob.content'),
  })
  if (!ok) {
    return
  }
  const { noContent, data, error } = yield http.delete(
    `/projects/${id}/jobs/${jobId}`
  )
  if (noContent) {
    yield put(getPlanning(id))
  } else if (error) {
    yield call(globalError, data)
  }
}
