import { call, put } from 'redux-saga/effects'
import { http } from 'Utils/http'
import { globalError } from './shell'
import {
  getCandidates,
  getCandidateSucceed,
  getCandidatesSucceed,
  getNotesSucceed,
  getRejectReasonsSucceed,
  setNotFound,
  setShare,
  updateCandidate,
} from 'Actions/share'
import { showMessage } from 'Actions/shell'
import { messageLevel } from 'Utils/constants'
import { t } from 'Root/app/IntlProvider'

export function* acceptCandidateSaga(action) {
  const { candidate, user, id, reject, resolve } = action.payload
  const { data, ok } = yield http.post(
    `/shares/${user}/${id}/${candidate}/accept`
  )
  if (ok) {
    yield put(updateCandidate(data))
    yield call(resolve)
  } else {
    yield call(reject)
    yield put(
      showMessage(t('share.share.confirm.error.accept'), messageLevel.error)
    )
  }
}

export function* getCandidateSaga(action) {
  const { candidate, id, user } = action
  const { ok, error, data, notFound, badRequest } = yield http.get(
    `/shares/${user}/${id}/${candidate}`
  )
  if (ok) {
    yield put(setNotFound(false))
    yield put(setShare(data.share))
    yield put(getCandidateSucceed(data.candidate))
  } else if (notFound || badRequest) {
    yield put(setNotFound(true))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getCandidatesSaga(action) {
  const { user, id } = action
  const { ok, error, data, notFound, badRequest } = yield http.get(
    `/shares/${user}/${id}`
  )
  if (ok) {
    yield put(setNotFound(false))
    yield put(setShare(data.share))
    yield put(getCandidatesSucceed(data.candidates))
  } else if (notFound || badRequest) {
    yield put(setNotFound(true))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getNotesSaga(action) {
  const { candidate, id, user } = action
  const { ok, error, data, notFound, badRequest } = yield http.get(
    `/shares/${user}/${id}/${candidate}/notes`
  )
  if (ok) {
    yield put(getNotesSucceed(data))
  } else if (notFound || badRequest) {
    //yield put(setNotFound(true))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getRejectReasonsSaga(action) {
  const { id, user } = action
  const { ok, data } = yield http.get(`/shares/${user}/${id}/reasons`)
  if (ok) {
    yield put(getRejectReasonsSucceed(data))
  }
}

export function* rejectCandidateSaga(action) {
  const { candidate, user, id, reason, reject, resolve } = action.payload
  const { data, ok } = yield http.post(
    `/shares/${user}/${id}/${candidate}/reject`,
    {
      reason,
    }
  )
  if (ok) {
    yield put(updateCandidate(data))
    yield call(resolve)
  } else {
    yield call(reject)
    yield put(
      showMessage(t('share.share.confirm.error.reject'), messageLevel.error)
    )
  }
}

export function* updateEvaluationSaga(action) {
  const { candidate, user, id, values } = action.payload
  const { data, ok } = yield http.post(
    `/shares/${user}/${id}/${candidate}/evaluation`,
    values
  )
  if (ok) {
    yield put(getNotesSucceed(data))
  } else {
    yield call(globalError, data)
  }
}

export function* updateNoteSaga(action) {
  const { candidate, user, id, reject, resolve, values } = action.payload
  const { data, ok } = yield http.post(
    `/shares/${user}/${id}/${candidate}/note`,
    values
  )
  if (ok) {
    yield put(getNotesSucceed(data))
    yield call(resolve)
  } else {
    yield call(globalError, data, reject)
  }
}

export function* updateViewerSaga(action) {
  const { user, id, values } = action.payload
  const { data, ok } = yield http.put(`/shares/${user}/${id}/viewer`, values)
  if (ok) {
    yield put(getCandidates(user, id))
  } else {
    yield call(globalError, data)
  }
}
