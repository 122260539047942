import React from 'react'
import compose from 'recompose/compose'
import clsx from 'clsx'
import getValue from 'get-value'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Field } from 'redux-form'
import TextField from './TextField'
import SelectField from './SelectField'
import IconButton from '@material-ui/core/IconButton'
import TableRowIcon from 'Icons/TableRow'
import TableRowMergeIcon from 'Icons/TableRowMerge'
import GridContainer from 'Components/common/GridContainer'
import GridItem from 'Components/common/GridItem'
import Tooltip from 'Components/common/Tooltip'
import { t } from 'Root/app/IntlProvider'
import { getByLine, getByZip } from 'Actions/address'

const styles = {
  button: {
    display: 'flex',
  },
  root: {
    display: 'flex',
    marginBottom: 4,
    marginTop: 4,
  },
}

const AddressField = (props) => {
  const { value, onChange } = props.input
  const handleButtonClick = () => {
    onChange(!value)
  }
  const { canSwitch, classes, countries, prefix, extensionName } = props

  const handleOnBlur = () => {
    const { addressDetails, getByZip, form } = props
    const { zipcode, number, country } = addressDetails
    if (zipcode && number && country === 'nl') {
      getByZip(zipcode, number, country, form, prefix)
    }
  }

  const handleOnAddressBlur = () => {
    const { address, getByLine, form } = props
    if (address) {
      getByLine(address, form, prefix)
    }
  }

  const Button = () => {
    if (canSwitch) {
      return (
        <div>
          <Tooltip
            title={t(
              `common.form.addressField.tooltip.${
                value ? 'nodetails' : 'details'
              }`
            )}
          >
            <IconButton onClick={handleButtonClick} tabIndex="-1">
              {value ? <TableRowMergeIcon /> : <TableRowIcon />}
            </IconButton>
          </Tooltip>
        </div>
      )
    }
    return null
  }

  const ext = extensionName || (prefix ? '-' + prefix : '')

  return (
    <div className={clsx(classes.button, value && classes.root)}>
      {!value && (
        <>
          <Field
            name={`address${prefix}`}
            component={TextField}
            placeholder={
              t('common.form.addressField.address.placeholder') + ext
            }
            label={t('common.form.addressField.address.label') + ext}
            spellCheck={false}
            onBlur={handleOnAddressBlur}
          />
          <Button />
        </>
      )}
      {value && (
        <GridContainer>
          <GridItem xs={6} md={3}>
            <Field
              name={`addressDetails${prefix}.country`}
              component={SelectField}
              label={t('common.form.addressField.country.label') + ext}
              items={countries}
              list="country"
            />
          </GridItem>
          <GridItem xs={4} md={2}>
            <Field
              name={`addressDetails${prefix}.zipcode`}
              component={TextField}
              spellCheck={false}
              label={t('common.form.addressField.zipcode.label') + ext}
              inputLabelProps={{
                shrink: true,
              }}
              upper
            />
          </GridItem>
          <GridItem xs={2} md={1}>
            <Field
              name={`addressDetails${prefix}.number`}
              component={TextField}
              spellCheck={false}
              label={t('common.form.addressField.number.label') + ext}
              inputLabelProps={{
                shrink: true,
              }}
              onBlur={handleOnBlur}
            />
          </GridItem>
          <GridItem xs={6} md={3}>
            <Field
              name={`addressDetails${prefix}.street`}
              component={TextField}
              spellCheck={false}
              label={t('common.form.addressField.street.label') + ext}
              inputLabelProps={{
                shrink: true,
              }}
            />
          </GridItem>
          <GridItem xs={6} md={3} className={classes.button}>
            <Field
              name={`addressDetails${prefix}.city`}
              component={TextField}
              spellCheck={false}
              label={t('common.form.addressField.city.label') + ext}
              inputLabelProps={{
                shrink: true,
              }}
            />
            <Button />
          </GridItem>
        </GridContainer>
      )}
    </div>
  )
}

export default compose(
  withStyles(styles),
  connect(
    (state, props) => {
      const form = getValue(props, 'meta.form', {
        default: '',
      })
      const prefix = props.prefix || ''
      return {
        address: getValue(state, `form.${form}.values.address${prefix}`, {
          default: '',
        }),
        addressDetails: getValue(
          state,
          `form.${form}.values.addressDetails${prefix}`,
          {
            default: {},
          }
        ),
        canSwitch:
          typeof props.canSwitch === 'undefined'
            ? state.auth.company.settings.canSwitchAddressDetails
            : props.canSwitch,
        countries: state.cache.lookups.country,
        form,
        prefix,
      }
    },
    { getByLine, getByZip }
  )
)(AddressField)
