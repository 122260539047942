import { combineReducers } from 'redux'
import * as types from 'Actions/types'
import { createReducer } from 'Utils/redux'
import initialState from './initialState'

const actionsReducer = createReducer(initialState.candidate.actions, {
  [types.CANDIDATE_SET_BARBUTTON]: (state, action) => {
    const { [action.button]: button, ...other } = state
    const { visible = true, disabled = true } = action
    return { ...other, [action.button]: { visible, disabled } }
  },
})

const dataReducer = createReducer(initialState.candidate.data, {
  [types.CANDIDATE_SET_DATA]: (state, { data }) => data,
  [types.CANDIDATE_ADD_EDUCATION_SUCCEED]: (state, { data }) => ({
    ...state,
    educations: data,
  }),
  [types.CANDIDATE_ADD_EMPLOYMENT_SUCCEED]: (state, { data }) => ({
    ...state,
    employments: data,
  }),
  [types.CANDIDATE_ADD_SKILL_SUCCEED]: (state, { data }) => ({
    ...state,
    skills: data,
  }),
  [types.CANDIDATE_DELETE_EDUCATION_SUCCEED]: (state, { id }) => ({
    ...state,
    educations: (state.educations || []).filter(
      (education) => education.id !== id
    ),
  }),
  [types.CANDIDATE_DELETE_EMPLOYMENT_SUCCEED]: (state, { id }) => ({
    ...state,
    employments: (state.employments || []).filter(
      (employment) => employment.id !== id
    ),
  }),
  [types.CANDIDATE_DELETE_SKILL_SUCCEED]: (state, { id }) => ({
    ...state,
    skills: (state.skills || []).filter((skill) => skill.id !== id),
  }),
  [types.CANDIDATE_UPDATE_EDUCATION_SUCCEED]: (state, { data }) => ({
    ...state,
    educations: data,
  }),
  [types.CANDIDATE_UPDATE_EMPLOYMENT_SUCCEED]: (state, { data }) => ({
    ...state,
    employments: data,
  }),
  [types.CANDIDATE_UPDATE_SKILL_SUCCEED]: (state, { data }) => ({
    ...state,
    skills: data,
  }),
})
const duplicatesReducer = createReducer(initialState.candidate.duplicates, {
  [types.CANDIDATE_ADD_DUPLICATES]: (state, action) => ({
    ...state,
    list: action.data,
  }),
})

const gdprReducer = createReducer(initialState.candidate.gdpr, {
  [types.CANDIDATE_GET_GDPR_INFO_SUCCEED]: (state, { values }) => ({
    ...state,
    ...values,
    loaded: true,
  }),
})

const jobsReducer = createReducer(initialState.candidate.jobs, {
  [types.CANDIDATE_GET_JOBS]: (state, action) => ({
    ...state,
    list: action.data,
    loaded: true,
  }),
  [types.CANDIDATE_GET_SHORTLIST]: (state, action) => ({
    ...state,
    shortlist: action.data,
    loaded: true,
  }),
  [types.CANDIDATE_SET_JOBS_FILTER]: (state, { filter }) => ({
    ...state,
    filter,
  }),
  [types.CANDIDATE_SET_JOBS_LOADED]: (state, { value }) => ({
    ...state,
    loaded: value,
  }),
  [types.CANDIDATE_SHORTLIST_CLEAR_SELECT]: (state) => ({
    ...state,
    selected: [],
  }),
  [types.CANDIDATE_SHORTLIST_DESELECT]: (state, { id }) => {
    const selection = [...state.selected].filter((x) => x !== id)
    return {
      ...state,
      selected: selection,
    }
  },
  [types.CANDIDATE_SHORTLIST_SELECT]: (state, { id }) => {
    const selection = [...state.selected]
    if (!selection.includes(id)) {
      selection.push(id)
    }
    return {
      ...state,
      selected: selection,
    }
  },
  [types.CANDIDATE_SHORTLIST_INVERSE_SELECTION]: (state, { ids = [] }) => {
    const selected = ids.reduce((tot, item) => {
      if (!state.selected.includes(item.id)) {
        tot.push(item.id)
      }
      return tot
    }, [])
    return {
      ...state,
      selected,
    }
  },
  [types.ENTITY_DESELECT_ITEMS]: (state) => ({
    ...state,
    selected: [],
  }),
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.candidate.jobs,
})

const mergeReducer = createReducer(initialState.candidate.merge, {
  [types.CANDIDATE_MERGE_ADD_INFO]: (state, action) => ({
    ...state,
    ...action.data,
  }),
  [types.CANDIDATE_MERGE_SELECT]: (state, { value }) => ({
    ...state,
    selected: value,
  }),
  [types.CANDIDATE_MERGE_RESET]: () => initialState.candidate.merge,
})

const parsingReducer = createReducer(initialState.candidate.parsing, {
  [types.CANDIDATE_GET_CREDITS_SUCCEED]: (state, { data }) => ({
    ...state,
    credits: data,
  }),
  [types.CANDIDATE_GET_JSON_SUCCEED]: (state, { data }) => ({
    ...state,
    json: data,
  }),
  [types.CANDIDATE_SET_PARSING_BUSY]: (state, { busy, resumeId }) => ({
    ...state,
    busy,
    resumeId,
  }),
  [types.CANDIDATE_MERGE_SELECT]: (state, { value }) => ({
    ...state,
    selected: value,
  }),
  [types.CANDIDATE_MERGE_RESET]: () => initialState.candidate.merge,
})

const resumeReducer = createReducer(initialState.candidate.resume, {
  [types.CANDIDATE_PDF_ZOOMIN]: (state) => ({
    ...state,
    scale: state.scale + 0.5,
  }),
  [types.CANDIDATE_PDF_ZOOMOUT]: (state) => ({
    ...state,
    scale: state.scale - 0.5,
  }),
  // [types.CANDIDATE_SET_UPLOADING]: (state, action) => ({
  //   ...state,
  //   uploading: action.value,
  // }),
})

const selectBarReducer = createReducer(initialState.candidate.selectBar, {
  [types.CANDIDATE_SELECTBAR_SET_JOBID]: (state, action) => ({
    ...state,
    jobId: action.value,
  }),
  [types.CANDIDATE_SELECTBAR_SET_MATCHING]: (state, action) => ({
    ...state,
    matching: action.value,
  }),
})

const shareReducer = createReducer(initialState.candidate.shares, {
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.candidate.shares,
  [types.CANDIDATE_GET_SHARES_SUCCEED]: (state, { data }) => ({
    ...state,
    list: data,
  }),
  [types.CANDIDATE_SET_SHARE_DEFAULTS]: (state, { data }) => ({
    ...state,
    defaults: data,
  }),
  [types.CANDIDATE_GET_SHARE_VIEWS_SUCCEED]: (state, { data }) => ({
    ...state,
    views: data,
  }),
  [types.CANDIDATE_GET_PROFILE_SHARES_SUCCEED]: (state, { data }) => ({
    ...state,
    profile: data,
  }),
  [types.CANDIDATE_GET_COMPANY_SHARES_SUCCEED]: (state, { data }) => ({
    ...state,
    company: data,
  }),
})
const sourcesReducer = createReducer(initialState.candidate.sources, {
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.candidate.sources,
  [types.AUTH_CHANGE_COMPANY_SUCCEED]: () => initialState.candidate.sources,
  [types.CANDIDATE_ADD_SOURCES]: (state, { data }) => ({
    ...state,
    data,
  }),
  [types.CANDIDATE_SET_SOURCES_DIRTY]: (state, { value }) => ({
    ...state,
    dirty: value,
  }),
})

export default combineReducers({
  actions: actionsReducer,
  data: dataReducer,
  duplicates: duplicatesReducer,
  gdpr: gdprReducer,
  jobs: jobsReducer,
  merge: mergeReducer,
  parsing: parsingReducer,
  resume: resumeReducer,
  selectBar: selectBarReducer,
  shares: shareReducer,
  sources: sourcesReducer,
})
