import { blueGrey } from '@material-ui/core/colors'
import is from 'is_js'
import moment from 'moment'
import storage from 'Utils/storage'

let language =
  storage.get('language') ||
  (navigator.language || navigator.userLanguage || []).split('-')[0]
language = ['nl', 'en'].includes(language) ? language : 'nl'
let type = storage.get(
  'palette',
  window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    ? 'dark'
    : 'light'
)
type = ['light', 'dark'].includes(type) ? type : 'light'
const assignedTo = storage.get('funnel.assignedTo', 'my')
const panels = JSON.parse(storage.get('panels', '{}'))
/* colors
  https://flatuicolors.com
*/
export default {
  activity: {
    activeUser: '',
    filter: '',
    info: { hasMore: false, loading: false, page: 1 },
    list: [],
  },
  app: {
    countries: {},
    decimalSeparator: ',',
    language,
    languages: {},
    navbarDocked:
      is.mobile() || window.innerWidth <= 600
        ? false
        : JSON.parse(storage.get('docked', true)),
    navbarOpen:
      is.mobile() || window.innerWidth <= 600
        ? false
        : JSON.parse(storage.get('docked', true)),
    online: true,
    panels,
    newVersion: false,
    thousandSeparator: ',',
    version: null,
  },
  apply: {},
  auth: {
    activate: {
      phone: '',
      resend: false,
    },
    company: {
      allBranches: [],
      branches: [],
      candidateUsers: [],
      hiring: {},
      id: '',
      jobUsers: [],
      name: '',
      nylasBeta: false,
      pusher: {
        key: '',
        cluster: '',
      },
      phases: [],
      relationUsers: [],
      saving: false,
      search: '',
      settings: {},
      sharedCandidates: [],
      sharedEmails: [],
      sharedJobs: [],
      sharedProjects: [],
      sharedRelations: [],
      users: [],
      webhooks: [],
      webhookTesting: false,
    },
    info: {
      authenticated: undefined,
      demo: false,
      directStart: false,
      initialized: true,
      previewMode: false,
      refresh: 0,
      subscriptionEnded: false,
      subscriptionDaysRemaining: undefined,
    },
    nylas: {
      accounts: [],
      busy: false,
    },
    partner: {},
    sessions: [],
    sources: {
      entity: 'candidate',
      filter: '',
      list: [],
      loading: false,
    },
    subscription: {},
    tags: {
      entity: 'candidate',
      filter: '',
      list: [],
      loading: false,
    },
    tenant: {
      name: '',
      plugins: {},
    },
    twoFactor: {
      code: '',
      url: '',
    },
    user: {
      id: '',
      name: '',
      email: storage.get('email'),
      language,
      nylas: [],
      phases: [],
      role: 'user',
      twoFactorEnabled: undefined,
      saving: false,
      settings: {},
    },
  },
  automation: {
    info: {
      loading: false,
    },
    list: [],
    log: [],
  },
  cache: {
    entities: {
      companies: [],
      companyFilter: '',
      users: [],
      userFilter: '',
      userFilterType: '',
      viewers: [],
    },
    filters: {
      list: [],
      loaded: false,
    },
    loading: {
      companies: false,
      templates: false,
      users: false,
    },
    lookups: {},
    tags: {
      candidate: [],
      job: [],
      project: [],
      relation: [],
    },
    templates: [],
  },
  candidate: {
    actions: {
      photo: {
        disabled: false,
        visible: false,
      },
      print: {
        disabled: false,
        visible: false,
      },
      zoomIn: {
        disabled: false,
        visible: false,
      },
      zoomOut: {
        disabled: false,
        visible: false,
      },
    },
    data: {
      educations: [],
      employments: [],
      skills: [],
    },
    duplicates: {
      list: [],
    },
    gdpr: {
      company: null,
      id: null,
      loaded: false,
      message: '',
      notFound: false,
    },
    jobs: {
      filter: '',
      list: [],
      loaded: false,
      selected: [],
      shortlist: [],
    },
    merge: {},
    parsing: {
      busy: false,
      credits: undefined,
      resumeId: undefined,
      json: null,
    },
    resume: {
      scale: 1.0,
      uploading: false,
    },
    selectBar: {
      jobId: null,
      matching: undefined,
    },
    shares: {
      company: [],
      defaults: {
        edit: ['notes'],
        view: ['contactData', 'profile', 'resume'],
      },
      list: [],
      profile: [],
      views: [],
    },
    sources: {
      data: [],
      dirty: true,
    },
  },
  certificate: {
    list: [],
    selectedIndex: '',
    busy: false,
  },
  dashboard: {
    info: {
      currentTab: '1',
      definitionLoaded: false,
      loading: false,
    },
    tab: {},
    widget: {},
  },
  deletion: {
    info: {},
  },
  email: {
    attachments: [],
    info: {
      uploading: 0,
      context: [],
    },
  },
  entity: {
    activities: {
      filter: '',
      info: { hasMore: false, loading: false, page: 1 },
      list: [],
    },
    attachments: [],
    certificates: [],
    data: {},
    emails: {
      filter: '',
      filterCandidate: '',
      loaded: false,
      list: [],
    },
    events: {
      limit: 2,
      list: [],
    },
    info: {
      confetti: false,
      contentOverflowX: 'hidden',
      contentOverflowY: 'auto',
      editForm: false,
      editMode: false,
      entityType: '',
      id: '',
      loading: false,
      loadingNumber: 0,
      notFound: '',
      prevLocation: '',
      saving: false,
      selection: [],
      selectionEntity: '',
      tabCaption: '',
      tabCount: 0,
    },
    insights: {
      error: false,
      reason: '',
      url: '',
    },
    lastNote: {},
    notes: {
      backUrl: '',
      filter: '',
      filterType: '',
      list: [],
      order: 'updatedAtDesc',
      uploadAttachment: [],
      users: [],
      viewers: [],
    },
    tasks: {
      list: [],
    },
    unsavedNote: {
      id: '',
      content: '',
      ref: null,
      type: '',
      rating: 0,
      secured: false,
    },
  },
  event: {
    calendar: {
      currentView: '',
      date: null,
      endAt: null,
      filter: '',
      loading: false,
      sharedBy: [],
      showUsers: false,
      startAt: null,
      users: [],
    },
    info: {
      formOpen: false,
    },
    item: {},
    itemList: [],
    list: [],
    locations: [],
  },
  export: {
    list: [],
    loading: false,
  },
  iframe: {
    loading: true,
    value: 0,
    visible: false,
  },
  import: {
    info: {
      busy: false,
    },
  },
  funnel: {
    assignedTo,
    confetti: false,
    lanes: [],
    timeline: [],
  },
  job: {
    actions: {
      fold: {
        visible: false,
      },
      print: {
        disabled: false,
        visible: false,
      },
      unfold: {
        visible: false,
      },
      share: {
        visible: false,
      },
      zoomIn: {
        disabled: false,
        visible: false,
      },
      zoomOut: {
        disabled: false,
        visible: false,
      },
    },
    board: {
      filter: '',
      filterId: '',
      filterOrigin: '',
      filterSelect: '',
      id: null,
      phases: [],
      placeholderIndex: -1,
      position: {
        left: null,
        top: null,
        id: null,
      },
      selectMode: false,
    },
    languages: {
      editmode: false,
      data: {},
    },
    reviewUsers: {
      list: [],
    },
    selectBar: {
      candidateId: null,
      matching: undefined,
    },
    netive: {
      data: {},
      json: {},
      proposal: {},
    },
    sources: {
      data: [],
      dirty: true,
    },
    text: {
      scale: 1.0,
      uploading: false,
    },
    timeline: {
      list: [],
    },
  },
  lookup: {
    child: [],
    language,
    list: [],
    loaded: false,
  },
  notification: {
    all: {
      list: [],
      loading: false,
    },
    new: {
      list: [],
    },
    subscription: {
      list: [],
      role: '',
    },
  },
  pdf: {
    creating: false,
  },
  project: {
    planning: {
      candidates: [],
      interval: {
        start: moment().add(-12, 'day').valueOf(),
        end: moment().add(12, 'day').valueOf(),
      },
      jobs: [],
      timelines: [],
    },
    sources: {
      data: [],
      dirty: true,
    },
    timelines: {
      groups: [],
      interval: {
        start: moment().add(-12, 'day').valueOf(),
        end: moment().add(12, 'day').valueOf(),
      },
      list: [],
      loading: false,
      position: {
        left: null,
        top: null,
        id: null,
      },
    },
  },
  quick: {
    hightlight: '',
    item: {},
    loading: false,
    loadingItem: false,
    list: [],
    selected: {},
    tab: 'all',
  },
  relation: {
    candidates: {
      filter: '',
      list: [],
      loaded: false,
      selected: [],
      shortlist: [],
    },
    claims: {
      list: [],
    },
    coc: {
      list: [],
    },
    contacts: {
      list: [],
    },
    jobs: {
      filter: '',
      list: [],
      loaded: false,
    },
    sources: {
      data: [],
      dirty: true,
    },
  },
  search: {
    list: [],
    noResult: false,
    selected: [],
    text: '',
    totals: {},
  },
  share: {
    candidate: {
      data: {},
      evaluation: { rating: 0, content: '' },
      note: '',
      notes: [],
    },
    candidates: [],
    company: {},
    info: {
      id: null,
      edit: [],
      notFound: false,
      view: [],
    },
    job: {},
    reasons: [],
    shares: [],
    user: {},
    viewer: {},
  },
  shell: {
    message: {
      duration: 5000,
      level: '',
      text: '',
      visible: false,
    },
  },
  table: {
    copy: {
      entity: '',
      entityId: null,
    },
    dashboardFilter: [],
    data: {
      filters: [],
      total: false,
      rows: [],
      selected: [],
      ranking: false,
      type: '',
    },
    filters: {},
    info: {
      clearFilters: false,
      entityType: '',
      filesLoading: false,
      filter: 'none',
      filterList: false,
      filterSearch: '',
      firstload: false,
      loading: false,
      matchId: '',
      matchName: '',
      order: 'default',
      prevLocation: '',
      searchField: 'all',
      searchKey: 0,
      searchText: '',
      scrollTop: null,
      skip: 0,
      tab: '',
    },
    insights: {
      error: false,
      reason: '',
      url: '',
    },
    matching: {
      data: {},
      loaded: false,
    },
  },
  task: {
    filter: '',
    formOpen: false,
    item: {},
    list: [],
    load: false,
    my: [],
    searchFilter: '',
    sharedBy: [],
    tab: 'todo',
    users: [],
  },
  template: {
    data: {
      attachments: [],
      context: '',
      language: '',
      uploadedAttachments: [],
    },
    filter: '',
    info: {
      uploading: false,
    },
    item: {
      locale: [],
    },
    media: {},
    medias: [],
  },
  theme: {
    palette: {
      accent: '#d35400',
      forceflow: {
        candidate: '#3498db',
        company: '#34495e',
        contact: '#e67e22',
        customer: '#e67e22',
        dashboard: '#607d8b',
        deletion: 'rgba(231,76,60, 0.9)',
        email: '#808080',
        event: '#808080',
        job: '#1abc9c',
        note: '#eccc68',
        noteBackground: '#ffffd5',
        project: '#5c6bc0',
        relation: '#e67e22',
        supplier: '#e67e22',
        task: '#808080',
        unknown: '#e74c3c',
        user: '#bdc3c7',
      },
      primary: {
        contrastText: 'rgba(255, 255, 255, 1)',
        dark: blueGrey[700],
        light: blueGrey[300],
        main: blueGrey[500],
      },
      secondary: {
        main: '#d35400',
      },
      type,
    },
    typography: {
      useNextVariants: true,
    },
  },
}
