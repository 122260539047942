import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { IntlProvider as Provider, createIntl } from 'react-intl'
// import messagesEn from '../translations/en.json'
// import messagesNl from '../translations/nl.json'
import {
  exists as utilsExists,
  translate as utilsTranslate,
  getEntity as utilsGetEntity,
} from 'Utils/translate'
import { entity as entityObj } from 'Utils/constants'

const messagesLib = {
  nl: () => import('../translations/nl.json'),
  en: () => import('../translations/en.json'),
}
export let intl
// export let intl = createIntl({
//   locale: 'nl',
//   messages: messages['nl'],
// })

export const t = (id, values) => utilsTranslate(intl)(id, values)

export const exists = (id) => utilsExists(intl)(id)

export const getDateFormat = (entity, field) => {
  const id = `collection.${entity}.${field}.format`
  const format = t(id)
  return format === id ? 'D-M-YYYY' : format
}

export const getNumberFormat = (entity, field) => {
  const id = `collection.${entity}.${field}.format`
  const format = t(id)
  return format === id ? '0,0' : format
}

export const getEntity = (entity, plural) => {
  try {
    if (plural) {
      return entityObj.plural[entity]
    }
    return entityObj.single[entity]
  } catch (e) {
    return undefined
  }
}

export const getEntityCaption = (entity, plural = false) =>
  utilsGetEntity(intl, entity, plural)

const IntlProvider = ({ children, lang }) => {
  const [provider, setProvider] = useState({})
  useEffect(() => {
    const loadMessages = async () => {
      const messagesForLocale = await messagesLib[lang]()
      const intlContext = createIntl({
        locale: lang,
        messages: messagesForLocale,
      })
      intl = intlContext
      setProvider({ locale: lang, messages: messagesForLocale })
    }
    loadMessages()
  }, [lang])

  const { locale, messages } = provider
  return (
    <>
      {locale && (
        <Provider locale={locale} messages={messages}>
          {children}
        </Provider>
      )}
    </>
  )
}

export default connect(
  (state) => ({
    lang: state.app.language,
  }),
  null
)(IntlProvider)
