import { call, delay, put, select } from 'redux-saga/effects'
import { http } from 'Utils/http'
import { hide, show } from 'redux-modal'
import {
  addDuplicates,
  addEducationSucceed,
  addEmploymentSucceed,
  addSkillSucceed,
  addSources,
  deleteAlternativeSucceed,
  deleteCoverSucceed,
  deleteEducationSucceed,
  deleteEmploymentSucceed,
  deletePhotoSucceed,
  deleteResumeSucceed,
  deleteSkillSucceed,
  getCompanySharesSucceed,
  getCreditsSucceed,
  getGdprInfoSucceed,
  getJobsSucceed,
  getJsonSucceed,
  getProfileSharesSucceed,
  getShareDefaults,
  getSharesSucceed,
  getShareViewsSucceed,
  getShortlist,
  getShortlistSucceed,
  mergeAddInfo,
  setShareDefaults,
  setParsingBusy,
  setUploading,
  updateEducationSucceed,
  updateEmploymentSucceed,
  updateSkillSucceed,
  uploadPhotoSucceed,
} from 'Actions/candidate'
import {
  askSendEmail,
  getPlacements,
  selectCandidates,
  checkPhases,
} from 'Actions/job'
import {
  deselectItems,
  get as getEntity,
  put as putEntity,
  refresh,
  setLoading,
  setNoteViewers,
  updateData,
} from 'Actions/entity'
import { gotoUrl } from 'Actions/app'
import { getNumber } from 'Actions/deletion'
import { load, deselectItem, setSelected } from 'Actions/table'
import { confirmSaga, globalError, messageSaga } from './shell'
import { t } from 'Root/app/IntlProvider'
import { updateField } from 'Actions/form'
import { showMessage } from 'Actions/shell'
import { messageLevel } from 'Utils/constants'
import { plugins, isPluginAvailable } from 'Selectors/auth'

export function* addEducationSaga(action) {
  const {
    payload: { reject, resolve, values },
  } = action
  const state = yield select()
  const { created, error, data } = yield http.post(
    `/candidates/${state.entity.data.id}/educations`,
    values
  )
  if (created) {
    yield put(addEducationSucceed(data))
    yield call(resolve)
  } else if (error) {
    yield call(globalError, data, reject)
  }
}

export function* addEmploymentSaga(action) {
  const {
    payload: { reject, resolve, values },
  } = action
  const state = yield select()
  const { created, error, data } = yield http.post(
    `/candidates/${state.entity.data.id}/employments`,
    values
  )
  if (created) {
    yield put(addEmploymentSucceed(data))
    yield call(resolve)
  } else if (error) {
    yield call(globalError, data, reject)
  }
}

export function* addShortlistSaga(action) {
  const { id, relation } = action
  if (Array.isArray(relation)) {
    const { ok, error, data } = yield http.post(`/candidates/${id}/shortlist`, {
      relations: relation,
    })
    if (ok) {
      // ok
      yield put(getShortlist(id))
    } else if (error) {
      yield call(globalError, data)
    }
  } else {
    const { ok, error, data } = yield http.post(
      `/candidates/${id}/shortlist/${relation}`
    )
    if (ok) {
      yield put(getShortlist(id))
    } else if (error) {
      yield call(globalError, data)
    }
  }
}

export function* addSkillSaga(action) {
  const {
    payload: { reject, resolve, values },
  } = action
  const state = yield select()
  const { created, error, data } = yield http.post(
    `/candidates/${state.entity.data.id}/skills`,
    values
  )
  if (created) {
    yield put(addSkillSucceed(data))
    yield call(resolve)
  } else if (error) {
    yield call(globalError, data, reject)
  }
}

export function* addTalentpoolSaga(action) {
  const { id, talentpool } = action
  const state = yield select()
  const talentpools = [...(state.entity.data.talentpools || [])]
  if (!talentpools.includes(talentpool)) {
    talentpools.push(talentpool)
  } else {
    return
  }
  yield put(updateData({ talentpools }))
  yield put(putEntity(id, { talentpools }))
}

export function* addToTalentpoolSaga(action) {
  const { id } = action
  const { ok, error, data } = yield http.post(`/candidates/${id}/talentpool`)
  if (ok) {
    // ok
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* checkDuplicate(action) {
  const {
    payload: { email, jobId, reject, resolve },
  } = action
  const { ok, error, data } = yield http.get(
    `/candidates/check-duplicate/${encodeURIComponent(email)}`
  )
  if (ok) {
    if (data.length) {
      const candidate = data.find((x) => x.visible)
      if (candidate) {
        const yes = yield call(confirmSaga, {
          title: t('candidate.message.duplicate.title'),
          content: t(
            `candidate.message.duplicate.content${jobId ? 'Job' : ''}`,
            {
              name: candidate.name,
            }
          ),
        })
        if (!yes) {
          return yield call(reject)
        } else {
          if (jobId) {
            yield put(selectCandidates(jobId, [candidate.id], true))
          } else {
            yield put(gotoUrl({ url: `/candidates/${candidate.id}` }))
          }
        }
      } else {
        yield call(messageSaga, {
          title: t('candidate.message.duplicate.title'),
          content: t('candidate.message.duplicate.contentNotVisible', {
            email,
          }),
        })
        return yield call(reject)
      }
    }
    yield call(resolve, data)
  } else if (error) {
    yield call(globalError, data, reject)
  }
}

export function* createShareSaga(action) {
  const { resolve, reject, ...values } = action.payload
  const { ok, error, data } = yield http.post('/candidates/shares', values)
  if (ok) {
    yield put(hide('shareEdit'))
    yield call(resolve, data.id)
    yield put(refresh())
    yield put(getPlacements())
  } else if (error) {
    yield call(globalError, data, reject)
  }
}

export function* deleteAlternativeSaga(action) {
  const { id } = action
  const ok = yield call(confirmSaga, {
    title: t('candidate.message.deleteAlternative.title'),
    content: t('candidate.message.deleteAlternative.content'),
  })
  if (!ok) {
    return
  }
  const { noContent, error, data } = yield http.delete(
    `/candidates/${id}/alternative`
  )
  if (noContent) {
    yield put(deleteAlternativeSucceed())
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* deleteCoverSaga(action) {
  const { id } = action
  const ok = yield call(confirmSaga, {
    title: t('candidate.message.deleteCover.title'),
    content: t('candidate.message.deleteCover.content'),
  })
  if (!ok) {
    return
  }
  const { noContent, error, data } = yield http.delete(
    `/candidates/${id}/cover`
  )
  if (noContent) {
    yield put(deleteCoverSucceed())
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* deleteEducationSaga(action) {
  const { id, name } = action
  const ok = yield call(confirmSaga, {
    title: t('candidate.message.deleteEducation.title'),
    content: t('candidate.message.deleteEducation.content', { name }),
  })
  if (!ok) {
    return
  }
  const state = yield select()
  const { noContent, data } = yield http.delete(
    `/candidates/${state.entity.data.id}/educations/${id}`
  )
  if (noContent) {
    yield put(deleteEducationSucceed(id))
  } else {
    yield call(globalError, data)
  }
}

export function* deleteEmploymentSaga(action) {
  const { id, name } = action
  const ok = yield call(confirmSaga, {
    title: t('candidate.message.deleteEmployment.title'),
    content: t('candidate.message.deleteEmployment.content', { name }),
  })
  if (!ok) {
    return
  }
  const state = yield select()
  const { noContent, data } = yield http.delete(
    `/candidates/${state.entity.data.id}/employments/${id}`
  )
  if (noContent) {
    yield put(deleteEmploymentSucceed(id))
  } else {
    yield call(globalError, data)
  }
}

export function* deletePhotoSaga(action) {
  const { id } = action
  const ok = yield call(confirmSaga, {
    title: t('candidate.message.deletePhoto.title'),
    content: t('candidate.message.deletePhoto.content'),
  })
  if (!ok) {
    return
  }
  const { noContent, error, data } = yield http.delete(
    `/candidates/${id}/photo`
  )
  if (noContent) {
    yield put(deletePhotoSucceed())
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* deleteResumeSaga(action) {
  const { id } = action
  const ok = yield call(confirmSaga, {
    title: t('candidate.message.deleteResume.title'),
    content: t('candidate.message.deleteResume.content'),
  })
  if (!ok) {
    return
  }
  const { noContent, error, data } = yield http.delete(
    `/candidates/${id}/resume`
  )
  if (noContent) {
    yield put(deleteResumeSucceed())
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* deleteSkillSaga(action) {
  const { id, name } = action
  const ok = yield call(confirmSaga, {
    title: t('candidate.message.deleteSkill.title'),
    content: t('candidate.message.deleteSkill.content', { name }),
  })
  if (!ok) {
    return
  }
  const state = yield select()
  const { noContent, data } = yield http.delete(
    `/candidates/${state.entity.data.id}/skills/${id}`
  )
  if (noContent) {
    yield put(deleteSkillSucceed(id))
  } else {
    yield call(globalError, data)
  }
}

export function* extendGdprSaga(action) {
  const {
    payload: { id, period, reject, resolve },
  } = action
  const state = yield select()
  const periodDescription = state.cache.lookups.gdprPeriod[period].name
  const ok = yield call(confirmSaga, {
    title: t('candidate.message.extendGdpr.title'),
    content: t('candidate.message.extendGdpr.content', {
      period: periodDescription.toLowerCase(),
    }),
  })
  if (!ok) {
    return yield call(reject)
  }
  yield put(putEntity(id, { gdpr: { period } }))
  yield call(resolve)
}

export function* getCompanySharesSaga() {
  const { ok, error, data } = yield http.get(`/companies/shares`)
  if (ok) {
    yield put(getCompanySharesSucceed(data))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getCreditsSaga() {
  const { ok, error, data } = yield http.get(`/resumes/credits`)
  if (ok) {
    yield put(getCreditsSucceed(data))
  } else if (error) {
    yield put(getCreditsSucceed(0))
    yield call(globalError, data)
  }
}

export function* getGdprInfoSaga(action) {
  const { token, gdprType } = action
  const { ok, error, notFound, data } = yield http.get(
    `/candidates/gdpr/${token}/${gdprType}`
  )
  if (ok) {
    yield put(getGdprInfoSucceed(data))
  } else if (notFound) {
    yield put(getGdprInfoSucceed({ notFound: true, loaded: true }))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getJobsSaga(action) {
  const { id } = action
  const { ok, error, data } = yield http.get(`/candidates/${id}/jobs`)
  if (ok) {
    yield put(getJobsSucceed(id, data))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getJsonSaga(action) {
  const { id } = action
  const { ok, error, data } = yield http.get(`/candidates/${id}/json`)
  if (ok) {
    yield put(getJsonSucceed(data))
  } else if (error) {
    yield put(getJsonSucceed(null))
    yield call(globalError, data)
  }
}

export function* getProfileSharesSaga() {
  const { ok, error, data } = yield http.get(`/users/shares`)
  if (ok) {
    yield put(getProfileSharesSucceed(data))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getSharesSaga(action) {
  let query = ''
  const { candidateId, jobId } = action.payload
  if (candidateId) {
    query = `?candidateId=${candidateId}`
  } else if (jobId) {
    query = `?jobId=${jobId}`
  }
  const { ok, error, data } = yield http.get(`/candidates/shares${query}`)
  if (ok) {
    yield put(getSharesSucceed(data))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getShareDefaultsSaga() {
  const { ok, error, data } = yield http.get(`/candidates/shares/defaults`)
  if (ok) {
    yield put(setShareDefaults(data))
  } else if (error) {
    // do nothing
  }
}

export function* getShareViewsSaga(action) {
  const { id } = action
  const { ok, error, data } = yield http.get(`/candidates/shares/${id}/views`)
  if (ok) {
    yield put(getShareViewsSucceed(data))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getShortlistSaga(action) {
  const { id } = action
  const { ok, error, data } = yield http.get(`/candidates/${id}/shortlist`)
  if (ok) {
    yield put(getShortlistSucceed(id, data))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getViewersSaga(action) {
  const { id } = action
  const { ok, error, data } = yield http.get(`/candidates/${id}/viewers`)
  if (ok) {
    yield put(setNoteViewers(data))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* loadDuplicatesSaga() {
  const { ok, data } = yield http.get('/candidates/sources')
  if (ok) {
    yield put(addDuplicates(data))
  } else {
    yield call(globalError, data)
  }
}

export function* loadSourcesSaga() {
  // const state = yield select()
  // const sources = state.candidate.sources
  // if (!sources.dirty) {
  //   return
  // }
  const { ok, data } = yield http.get('/candidates/sources')
  if (ok) {
    yield put(addSources(data))
    //yield put(setSourcesDirty(false))
  } else {
    yield call(globalError, data)
  }
}

export function* mergeSaga(action) {
  const {
    payload: { resolve, reject },
  } = action
  const state = yield select()
  const id1 = state.candidate.merge.selected
  const id2 = 3 - id1
  const candidate1 = state.candidate.merge[`candidate${id1}`].id
  const candidate2 = state.candidate.merge[`candidate${id2}`].id
  const { ok, data } = yield http.post(
    `/candidates/merge/${candidate1}/${candidate2}`
  )
  if (ok) {
    // wait till indexed
    yield delay(2000)
    yield put(setSelected([]))
    yield put(load(state.table.info.skip))
    yield delay(1000)
    yield put(getNumber())
    yield call(resolve)
  } else {
    yield call(globalError, data, reject)
  }
}

export function* mergeGetInfoSaga() {
  const state = yield select()
  const candidate1 = state.table.data.selected[0]
  const candidate2 = state.table.data.selected[1]
  const { ok, data } = yield http.get(
    `/candidates/merge-info/${candidate1}/${candidate2}`
  )
  if (ok) {
    yield put(mergeAddInfo(data))
  } else {
    yield call(globalError, data)
  }
}

export function* parseExistingResumeSaga(action) {
  const { id } = action
  const parse = yield call(confirmSaga, {
    title: t('candidate.candidateResume.message.parsing.title'),
    content: t('candidate.candidateResume.message.parsing.content'),
  })
  if (!parse) {
    return
  }
  yield put(setLoading(true))
  const { ok, unprocessableEntity, error, data } = yield http.post(
    `/candidates/${id}/resume/parse`
  )
  if (ok) {
    yield put(getEntity(id))
    yield delay(1500)
  } else if (unprocessableEntity) {
    if (data.error === 'No parsing credits') {
      yield put(
        showMessage(
          t('candidate.addCandidate.error.resume.noCredits'),
          messageLevel.error
        )
      )
    } else {
      yield put(
        showMessage(
          t('candidate.addCandidate.error.resume.invalid'),
          messageLevel.error
        )
      )
    }
  } else if (error) {
    yield put(setParsingBusy(false))
    yield call(globalError, data)
  }
  yield put(setLoading(false))
}

export function* parseResumeSaga(action) {
  const { values } = action
  const state = yield select()
  if (state.candidate.parsing.busy) {
    return
  }
  yield put(setParsingBusy(true))
  let { ok, unprocessableEntity, error, tooLarge, data } = yield http.post(
    `/resumes/`,
    {
      ...values,
    }
  )
  if (ok) {
    // if (data.status === 'parsing') {
    //   let busy = true
    //   let i = 0
    //   while (busy) {
    //     i++
    //     yield delay(2000)
    //     const {
    //       ok: okGet,
    //       error: errorGet,
    //       data: dataGet,
    //     } = yield http.get(`/resumes/${data.resumeId}`)
    //     if (okGet) {
    //       if (dataGet.status === 'ready') {
    //         data = dataGet
    //         busy = false
    //       } else if (dataGet.status === 'error') {
    //         yield put(
    //           showMessage(
    //             t('candidate.addCandidate.error.resume.invalid'),
    //             messageLevel.error
    //           )
    //         )
    //         return yield put(setParsingBusy(false))
    //       }
    //     } else if (errorGet) {
    //       yield put(setParsingBusy(false))
    //       return yield call(globalError, dataGet)
    //     }
    //     // timeout
    //     if (i > 60) {
    //       yield put(
    //         showMessage(
    //           t('candidate.addCandidate.error.resume.invalid'),
    //           messageLevel.error
    //         )
    //       )
    //       return yield put(setParsingBusy(false))
    //     }
    //   }
    // }
    for (const field in data) {
      if (field === 'status') {
        continue
      }
      const value = data[field]
      if (value && typeof value === 'object' && value.constructor === Object) {
        for (const item in value) {
          const itemValue = value[item]
          yield put(
            updateField(
              { form: 'addCandidate', field: `${field}.${item}`, touch: true },
              itemValue
            )
          )
        }
      } else {
        yield put(
          updateField({ form: 'addCandidate', field, touch: true }, value)
        )
      }
    }
    yield put(setParsingBusy(false, data.parsedResumeId))
  } else if (unprocessableEntity) {
    if (data.error === 'No parsing credits') {
      yield put(
        showMessage(
          t('candidate.addCandidate.error.resume.noCredits'),
          messageLevel.error
        )
      )
    } else {
      yield put(
        showMessage(
          t('candidate.addCandidate.error.resume.invalid'),
          messageLevel.error
        )
      )
    }
    yield put(setParsingBusy(false))
  } else if (tooLarge) {
    yield put(
      showMessage(
        t('candidate.addCandidate.error.resume.tooLarge'),
        messageLevel.error
      )
    )
    yield put(setParsingBusy(false))
  } else if (error) {
    yield put(setParsingBusy(false))
    yield call(globalError, data)
  }
}

export function* processRequestGdprSaga(action) {
  const {
    payload: { candidateId, id, reject, resolve, type },
  } = action
  if (type !== 'consent') {
    const ok = yield call(confirmSaga, {
      title: t(`candidate.message.${type}Gdpr.title`),
      content: t(`candidate.message.${type}Gdpr.content`),
      options: { delay: 3000 },
    })
    if (!ok) {
      return yield call(reject)
    }
  }
  const state = yield select()
  yield http.put(`/candidates/gdpr/request/${candidateId}/${id}`)
  if (['change', 'consent'].includes(type)) {
    yield put(getEntity(candidateId))
  } else {
    yield delay(3000)
    yield put(gotoUrl({ url: `/candidates` }))
    yield put(load(state.table.info.skip))
    yield put(deselectItem(candidateId))
    yield put(getNumber())
  }
  yield call(resolve)
}

export function* removeFromTalentpoolSaga(action) {
  const { id } = action
  const { ok, error, data } = yield http.delete(`/candidates/${id}/talentpool`)
  if (ok) {
    // ok
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* removeShareSaga(action) {
  const { id } = action
  const ok = yield call(confirmSaga, {
    title: t(`candidate.message.removeShare.title`),
    content: t(`candidate.message.removeShare.content`),
  })
  if (!ok) {
    return
  }
  const { noContent, error, data } = yield http.delete(
    `/candidates/shares/${id}`
  )
  if (noContent) {
    yield put(hide('shareEdit'))
    yield put(refresh())
    yield put(getPlacements())
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* removeShortlistSaga(action) {
  const { id, relationIds } = action
  const { ok, error, data } = yield http.post(
    `/candidates/${id}/shortlist/remove`,
    { relationIds }
  )
  if (ok) {
    yield put(getShortlist(id))
    yield put(deselectItems())
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* removeTalentpoolSaga(action) {
  const { id, talentpool } = action
  const state = yield select()
  let talentpools = [...(state.entity.data.talentpools || [])]
  if (talentpools.includes(talentpool)) {
    talentpools = talentpools.filter((x) => x !== talentpool)
  } else {
    return
  }
  yield put(updateData({ talentpools }))
  yield put(putEntity(id, { talentpools }))
}
export function* selectJobsSaga(action) {
  const { id, jobs } = action
  const { created, error, data } = yield http.post(`/candidates/${id}/jobs/`, {
    jobs,
  })
  if (created) {
    yield put(gotoUrl({ url: `/candidates/${id}/jobs` }))
    yield put(checkPhases())
    yield put(setSelected([]))
    if (data.askEmail) {
      yield put(
        askSendEmail({
          ...data.askEmail,
          phase: 'selected',
        })
      )
    }
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* sendGdprConsentSaga(action) {
  const {
    payload: { resolve, id },
  } = action
  const { ok, error } = yield http.post(`/candidates/${id}/send-consent`)
  if (ok) {
    yield put(
      showMessage(t('candidate.message.gdpr.consentSend'), messageLevel.info)
    )
    yield put(getEntity(id))
    yield call(resolve)
  } else if (error) {
    yield put(
      showMessage(
        t('candidate.message.gdpr.consentSendError'),
        messageLevel.error
      )
    )
    yield call(resolve)
  }
}

export function* sendGdprRequestSaga(action) {
  const {
    payload: { resolve, reject, values },
  } = action
  const { type, token, ...rest } = values
  const { ok, error } = yield http.put(
    `/candidates/gdpr/${token}/${type}`,
    rest
  )
  if (ok) {
    yield call(resolve)
  } else if (error) {
    yield put(getGdprInfoSucceed({ notFound: true }))
    yield call(reject)
  }
}

export function* sendShareSaga(action) {
  const { id, viewers } = action.payload
  if (!id || (viewers | []).length === 0) {
    return yield put(
      showMessage(t('candidate.share.send.error', messageLevel.error))
    )
  }
  const { ok, badGateway, error, data } = yield http.post(
    `/candidates/shares/${id}/send`,
    {
      viewers,
    }
  )
  if (ok) {
    yield put(
      showMessage(
        t('candidate.share.send.message', { number: viewers.length }),
        messageLevel.info
      )
    )
  } else if (error) {
    if (badGateway) {
      yield put(
        showMessage(
          t('candidate.share.send.invalid', {
            emails: (data.emails || []).join(', '),
          }),
          messageLevel.error,
          10000
        )
      )
    } else {
      yield call(globalError, data)
    }
  }
}

export function* showSharesSaga(action) {
  let query = ''
  const { candidate, job } = action.payload
  if (candidate && job) {
    query = `?jobId=${job}&candidateId=${candidate}`
  } else if (candidate) {
    query = `?candidateId=${candidate}`
  } else if (job) {
    query = `?jobId=${job}`
  }
  yield put(getShareDefaults())
  const { ok, error, data } = yield http.get(`/candidates/shares${query}`)
  if (ok) {
    yield put(getSharesSucceed(data))
    yield delay(100)
    if (data.length > 0) {
      yield put(show('shareList', action.payload))
    } else {
      yield put(show('shareEdit', action.payload))
    }
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* updateEducationSaga(action) {
  const {
    payload: { id, reject, resolve, values },
  } = action
  const state = yield select()
  const { ok, error, data } = yield http.put(
    `/candidates/${state.entity.data.id}/educations/${id}`,
    values
  )
  if (ok) {
    yield put(updateEducationSucceed(data))
    yield call(resolve)
  } else if (error) {
    yield call(globalError, data, reject)
  }
}

export function* updateEmploymentSaga(action) {
  const {
    payload: { id, reject, resolve, values },
  } = action
  const state = yield select()
  const { ok, error, data } = yield http.put(
    `/candidates/${state.entity.data.id}/employments/${id}`,
    values
  )
  if (ok) {
    yield put(updateEmploymentSucceed(data))
    yield call(resolve)
  } else if (error) {
    yield call(globalError, data, reject)
  }
}

export function* updateRatingSaga(action) {
  const { id, value } = action
  const { ok, data } = yield http.put(`/candidates/${id}/rating/`, {
    value,
  })
  if (!ok) {
    yield call(globalError, data)
  }
}

export function* updateShareSaga(action) {
  const { id, resolve, reject, ...values } = action.payload
  const { ok, error, data } = yield http.put(`/candidates/shares/${id}`, values)
  if (ok) {
    yield put(hide('shareEdit'))
    if (resolve) {
      yield call(resolve)
    }
    yield put(refresh())
    yield put(getPlacements())
  } else if (error) {
    yield call(globalError, data, reject)
  }
}

export function* updateSkillSaga(action) {
  const {
    payload: { id, reject, resolve, values },
  } = action
  const state = yield select()
  const { ok, error, data } = yield http.put(
    `/candidates/${state.entity.data.id}/skills/${id}`,
    values
  )
  if (ok) {
    yield put(updateSkillSucceed(data))
    yield call(resolve)
  } else if (error) {
    yield call(globalError, data, reject)
  }
}

export function* upgradeResumeSaga(action) {
  const { id } = action
  yield put(setParsingBusy(true))
  const { ok, data } = yield http.post(`/candidates/${id}/upgrade-resume`)
  if (ok) {
    yield put(updateSkillSucceed(data.skills))
    yield put(updateEducationSucceed(data.educations))
    yield put(updateEmploymentSucceed(data.employments))
  } else {
    yield call(globalError, data)
  }
  yield put(setParsingBusy(false))
}

export function* uploadAlternativeSaga(action) {
  const { id, file } = action
  yield put(setUploading('alternative'))
  const { created, error, data, tooLarge } = yield http.post(
    `/candidates/${id}/alternative`,
    file
  )
  if (created) {
    // yield put(uploadAlternativeSucceed(id, data))
    return
  } else if (tooLarge) {
    yield put(
      showMessage(
        t('candidate.message.uploadResume.error.tooLarge', { file: file.name }),
        messageLevel.error
      )
    )
  } else if (error) {
    yield call(globalError, data)
  }
  yield put(setUploading('alternative', false))
}

export function* uploadCoverSaga(action) {
  const { id, file } = action
  yield put(setUploading('cover'))
  const { created, error, data, tooLarge } = yield http.post(
    `/candidates/${id}/cover`,
    file
  )
  if (created) {
    // yield put(uploadCoverSucceed(id, data))
    return
  } else if (tooLarge) {
    yield put(
      showMessage(
        t('candidate.message.uploadResume.error.tooLarge', { file: file.name }),
        messageLevel.error
      )
    )
  } else if (error) {
    yield call(globalError, data)
  }
  yield put(setUploading('cover', false))
}

export function* uploadPhotoSaga(action) {
  const { id, file } = action
  const ext = ((file || {}).name || '').toLowerCase().split('.').pop()
  const types = ['jpg', 'jpeg', 'png', 'bmp', 'webp', 'heic', 'heif', 'svg']
  if (!types.includes(ext)) {
    // message
    yield put(
      showMessage(
        t('common.form.upload.error.file', { types: types.join(', ') }),
        messageLevel.error
      )
    )
    return
  }
  const { ok, error, data } = yield http.post(`/candidates/${id}/photo`, file)
  if (ok) {
    yield put(uploadPhotoSucceed(id, data.url))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* uploadResumeSaga(action) {
  const { id, file } = action
  const state = yield select()
  let parse = false
  if (
    state.auth.company.settings.resumeParsing &&
    isPluginAvailable(state, plugins.resumeParsing) &&
    state.candidate.parsing.credits > 0 &&
    !(
      state.auth.company.settings.resumeParsingMax &&
      state.entity.data.resumeProcessed
    )
  ) {
    parse = yield call(confirmSaga, {
      title: t('candidate.candidateResume.message.parsing.title'),
      content: t('candidate.candidateResume.message.parsing.content'),
    })
  }
  yield put(setUploading('resume'))
  const { created, error, data, tooLarge, unprocessableEntity } =
    yield http.post(`/candidates/${id}/resume`, { ...file, parse })
  if (created) {
    if (parse) {
      yield put(getEntity(id))
      yield delay(1500)
    }
    return
  } else if (unprocessableEntity) {
    if (data.error === 'No parsing credits') {
      yield put(
        showMessage(
          t('candidate.addCandidate.error.resume.noCredits'),
          messageLevel.error
        )
      )
    } else {
      yield put(
        showMessage(
          t('candidate.addCandidate.error.resume.invalid'),
          messageLevel.error
        )
      )
    }
  } else if (tooLarge) {
    yield put(
      showMessage(
        t('candidate.message.uploadResume.error.tooLarge', { file: file.name }),
        messageLevel.error
      )
    )
  } else if (error) {
    yield call(globalError, data)
  }
  yield put(setUploading('resume', false))
}
