import { call, put, select, take } from 'redux-saga/effects'
import { hide } from 'redux-modal'
import moment from 'moment'
import { http } from 'Utils/http'
import { show } from 'redux-modal'
import { reset, submit } from 'redux-form'
import {
  getItemRangeSucceed,
  getItemSucceed,
  getLocationsSucceed,
  getRange,
  getRangeSucceed,
  getSharedAgendasSucceed,
  rangeAdd,
  removeSucceed,
  saveFinished,
  setSharedUsers,
  sharedAgendasChanged,
  toggleShowUsers,
  update,
  updateItem,
  updateSucceed,
} from 'Actions/event'
import { confirmSaga, globalError } from './shell'
import { t } from 'Root/app/IntlProvider'
import { showMessage } from 'Actions/shell'
import { messageLevel } from 'Utils/constants'
import * as types from 'Actions/types'

export function* createSaga(action) {
  const {
    payload: { values, resolve, reject },
  } = action
  const { created, data } = yield http.post(`/events`, values)
  if (created) {
    yield put(updateItem(data))
    yield put(rangeAdd(data))
    yield call(resolve, data.id)
  } else {
    yield call(globalError, data, reject)
  }
  yield put(saveFinished())
}

export function* downloadIcs(action) {
  const {
    payload: { id, resolve, reject },
  } = action
  const { ok, data } = yield http.get(`/events/ics/${id}`)
  if (ok) {
    yield call(resolve, data)
  } else {
    yield call(globalError, data, reject)
  }
}

export function* getItemRangeSaga(action) {
  let { startAt, endAt, id, users } = action
  const state = yield select()
  if (!startAt) {
    startAt = Date.now()
  }
  if (!endAt) {
    endAt = Date.now()
  }
  if (!users) {
    users = [state.auth.user.id]
  }
  const { ok, data } = yield http.post(`/events/range`, {
    startAt,
    endAt,
    users,
  })
  if (ok) {
    if (id === 'new') {
      const state = yield select()
      data.push(state.event.item || {})
    }
    yield put(getItemRangeSucceed(data, id))
  } else {
    yield call(globalError, data)
  }
}

export function* getItemSaga(action) {
  const { callback, id, onLinkClick, view } = action.payload
  if (id === 'new') {
    return
  }
  const { ok, data, notFound } = yield http.get(`/events/${id}`)
  if (ok) {
    yield put(getItemSucceed(data))
    const form = view ? 'eventView' : 'eventItem'
    yield put(show(form, { callback, id, onLinkClick }))
  } else if (notFound) {
    yield put(showMessage(t('event.message.notFound'), messageLevel.error))
  } else {
    yield call(globalError, data)
  }
}

export function* getLocationsSaga(action) {
  const { payload } = action
  const { ok, data } = yield http.post(`/events/locations`, payload)
  if (ok) {
    yield put(getLocationsSucceed(data))
  } else {
    yield call(globalError, data)
  }
}

export function* getRangeSaga(action) {
  let { startAt, endAt, users } = action
  const state = yield select()
  if (!startAt) {
    startAt = state.event.calendar.startAt
  }
  if (!endAt) {
    endAt = state.event.calendar.endAt
  }
  if (!users) {
    users = [state.auth.user.id, ...state.event.calendar.users]
  }
  const { ok, data } = yield http.post(`/events/range`, {
    startAt,
    endAt,
    users,
  })
  if (ok) {
    yield put(getRangeSucceed(data, startAt, endAt))
  } else {
    yield call(globalError, data)
  }
}

export function* getSharedAgendasSaga() {
  const { ok, data } = yield http.get(`/events/shared-agendas`)
  if (ok) {
    yield put(getSharedAgendasSucceed(data))
    const state = yield select()
    const users = state.event.calendar.users
    const realUsers = (users || []).filter((user) =>
      data.find((x) => x.id === user)
    )
    if (users.join('') !== realUsers.join('')) {
      yield put(sharedAgendasChanged(realUsers))
    }
  } else {
    yield call(globalError, data)
  }
}

export function* removeSaga(action) {
  const { id } = action
  const state = yield select()
  let users = state.event.item.users
  const userId = state.auth.user.id
  if (users.length > 1 && users.includes(userId)) {
    const result = yield call(confirmSaga, {
      title: t('event.message.remove.title'),
      content: t('event.message.remove.contentMultiple'),
      options: {
        cancelVisible: true,
        cancelCaption: t('event.message.remove.button.cancel'),
        noCaption: t('event.message.remove.button.no'),
        yesCaption: t('event.message.remove.button.yes'),
      },
    })
    if (result === null) {
      return
    } else if (result === false) {
      // update users
      users = users.filter((id) => id !== userId)
      yield put(update({ id, values: { users }, refresh: true }))
      yield put(hide('eventView'))
      return
    }
  } else {
    const ok = yield call(confirmSaga, {
      title: t('event.message.remove.title'),
      content: t('event.message.remove.content'),
    })
    if (!ok) {
      return
    }
  }
  yield put(hide('eventItem'))
  yield put(hide('eventView'))
  const { noContent, data } = yield http.delete(`/events/${id}`)
  if (noContent) {
    // ok
    yield put(removeSucceed(id))
  } else {
    yield call(globalError, data)
  }
}

export function* removeRecurrencySaga(action) {
  const { id } = action

  const result = yield call(confirmSaga, {
    title: t('event.message.removeRecurrency.title'),
    content: t('event.message.removeRecurrency.content'),
  })
  if (result) {
    yield http.delete(`/events/recurrency/${id}`)
  }
}

export function* setNewItemSaga(action) {
  const { values = {}, callback } = action
  const {
    allDay,
    candidateId,
    candidateName,
    contact1Id,
    contact1Name,
    endAt,
    jobId,
    jobName,
    relationId,
    relationName,
    startAt,
    users,
  } = values
  const { ok, data } = startAt
    ? yield http.get(
        `/events/duration?startAt=${moment(
          startAt
        ).toISOString()}&endAt=${moment(endAt).toISOString()}`
      )
    : yield { ok: true, data: '60' }
  if (ok) {
    const state = yield select()
    yield put(
      getItemSucceed({
        id: 'new',
        isDraggable: true,
        startAt: startAt || moment().add(1, 'hour').startOf('hour'),
        candidateId,
        candidateName,
        contact1Id,
        contact1Name,
        title: candidateName || contact1Name || relationName,
        jobId,
        jobName,
        private: false,
        relationId,
        relationName,
        reminder: state.auth.user.settings.defaultReminder,
        endAt: endAt || moment().add(2, 'hour').startOf('hour'),
        allDay,
        users: [...new Set([state.auth.user.id, ...(users || [])])],
        usersPresent: true,
        type: 'onsite',
        duration: data,
      })
    )
    yield put(show('eventItem', { id: 'new', callback }))
  } else {
    yield call(globalError, data)
  }
}

export function* sharedAgendasChangedSaga(action) {
  const { users } = action
  yield put(setSharedUsers(users))
  const state = yield select()
  if (users.length === 0 && state.event.calendar.showUsers) {
    yield put(toggleShowUsers())
  }
  yield put(getRange(null, null, [state.auth.user.id, ...users]))
}

export function* updateSaga(action) {
  const {
    payload: { id, values, refresh, resolve, reject },
  } = action
  const { startAt, endAt, allDay } = values
  if (startAt || endAt || typeof allDay !== 'undefined') {
    yield put(updateItem({ id, startAt, endAt, allDay }))
  }
  const { ok, data } = yield http.put(`/events/${id}`, values)
  if (!ok) {
    yield put(getRange())

    return yield call(globalError, data, reject)
  }
  //yield put(updateItem(data))
  yield put(updateSucceed(data))
  if (resolve) {
    yield call(resolve)
  }
  yield put(saveFinished())
  if (refresh) {
    yield put(getRange())
  }
}

export function* saveNewSaga(action) {
  const { resolve } = action
  yield put(submit('eventItem'))
  yield take(types.EVENT_SAVE_FINISHED)
  yield put(reset('eventItem'))
  yield call(resolve, true)
}
